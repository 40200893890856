import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const customTheme = {
  dark: false,
  colors: {
    white: "#E8E3E6",
    purple: "#B040C0",
    yellow: "#F8F880",
  },
};

Vue.use(Vuetify, { customTheme });

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        background: "#E8E3E6",
        surface: "#E8E3E6",
        primary: "#B040C0",
        secondary: "#F8F880",
        white: "#E8E3E6",
        purple: "#B040C0",
        yellow: "#F8F880",
      },
    },
  },
});

export default vuetify;
