import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/smallCrafts",
    name: "smallCrafts",
    component: () => import("../views/SmallCrafts.vue"),
  },
  {
    path: "/greetingCards",
    name: "greetingCards",
    component: () => import("../views/GreetingCards.vue"),
  },
  {
    path: "/infinityLights",
    name: "infinityLights",
    component: () => import("../views/InfinityLights.vue"),
  },
  {
    path: "/memorialArrangements",
    name: "memorialArrangements",
    component: () => import("../views/MemorialArrangements.vue"),
  },
  {
    path: "/glassArt",
    name: "glassArt",
    component: () => import("../views/GlassArt.vue"),
  },
  {
    path: "/wreathsPage",
    name: "wreathsPage",
    component: () => import("../views/WreathsPage.vue"),
  },
  {
    path: "/upcomingEvents",
    name: "upcomingEvents",
    component: () => import("../views/UpcomingEvents.vue"),
  },
  {
    path: "/aboutPage",
    name: "aboutPage",
    component: () => import("../views/AboutPage.vue"),
  },
  {
    path: "/homemadeSoaps",
    name: "homemadeSoaps",
    component: () => import("../views/HomemadeSoaps.vue"),
  },
  {
    path: "/garageSale",
    name: "garageSale",
    component: () => import("../views/GarageSale.vue"),
  },
  {
    path: "/workInProcess",
    name: "workInProcess",
    component: () => import("../views/WorkInProcess.vue"),
  },
  {
    path: "/itemTest",
    name: "itemTest",
    component: () => import("../views/ItemTest.vue"),
  },
  {
    path: "/GalleryPics",
    name: "galeryPics",
    component: () => import("../views/GalleryPics.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
