<template>
  <v-app theme="light">
    <v-app-bar app light color="purple" height="75">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="yellow" />
      <v-card width="100%"></v-card>
      <div class="d-flex align-right pa">
        <v-img
          alt="KrafTee Lady Logo"
          class="mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="100"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app color="purple lighten-1">
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item
            v-for="page in pages"
            :key="page.title"
            link
            :to="page.link"
            color="yellow"
          >
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-footer color="primary" padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Kraf-Tee Lady</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  name: "App",

  data: () => ({
    drawer: null,
    group: null,
    pages: [
      { title: "Home", 
        link: "/",
        image: "" },
      {
        title: "Small Crafts",
        link: "/smallCrafts",
        image: "../assets/Crafts.png",
      },
      {
        title: "Greeting Cards",
        link: "/greetingCards",
        image: "",
      },
      // {
      //   title: "Homemade Soaps",
      //   link: "/homemadeSoaps",
      //   image: "../assets/Soap.png",
      // },
      {
        title: "Infinity Lights",
        link: "/infinityLights",
        image: "../assets/InfinityLight.png",
      },
      {
        title: "Memorial Arrangements",
        link: "/memorialArrangements",
        image: "../assets/flower.png",
      },
      {
        title: "Glass Art",
        link: "/glassArt",
        image: "",
      },
      {
        title: "Wreaths",
        link: "/wreathsPage",
        image: "",
      },
      // { title: "Work In Process", link: "/workInProcess", image: "" },
      {
        title: "Garage Sale",
        link: "/garageSale",
        image: "",
      },
      {
        title: "Upcoming Events",
        link: "/upcomingEvents",
        image: "",
      },
      {
        title: "About",
        link: "/AboutPage",
        image: "",
      },
      {
        title: "Item Test",
        link: "/ItemTest",
        image: "",
      },      
    ],
  }),
};
</script>
